import path from "path";

let __dirname;

if (typeof __dirname === "undefined") {
	if (
		typeof process !== "undefined" &&
		process.versions &&
		process.versions.node
	) {
		__dirname = path.dirname(process.argv[1]);
	} else {
		__dirname = "/";
	}
}

// ...existing code...

const templatesPath = "/data/templates.lua";

async function loadTemplates() {
	const response = await fetch(templatesPath);
	const templatesContent = await response.text();

	const templates = {};
	const templateRegex =
		/-------------------- Template: (\w+)\n([\s\S]*?)(?=-------------------- Template: |\n$)/g;
	let match;

	while ((match = templateRegex.exec(templatesContent)) !== null) {
		const [, name, content] = match;
		templates[name] = content.trim();
	}

	return templates;
}

const templatesPromise = loadTemplates();

export default templatesPromise;
